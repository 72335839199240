




























































import { Component, Vue  } from "vue-property-decorator";
import '@/assets/css/user/main.css';
import '@/assets/css/login/util.css';
import ErrorText from "@/views/user/componets/ErrorText.vue";
import {EMAIL} from "@/interface/email";
import AUTH_TYPE = EMAIL.AUTH_TYPE;
@Component({
  components:{ ErrorText }
})

export default class FindPW extends Vue {

  $refs!: { [key:string]: any }
  pending: boolean;
  mailSend : boolean;
  //user id, pw
  userEmail: string | "";

  //user info
  isRegEmail: boolean;
  okEmail : boolean;
  isUser : boolean;

  constructor() {
    super();
    this.pending = false;
    this.okEmail = false;
    this.isRegEmail = true;
    this.mailSend = false;
    this.userEmail = "";
    this.isUser = true;
  }

  created() {
    const isLogin = this.$store.getters.isLogin;
    if (isLogin) this.$router.push('/project/list');
  }

  async emailCheck() {
    //공백제거
    if (this.userEmail.trim()) {
      const sendData = {
        userEmail: this.userEmail
      }
      const emailRegCheck = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*[.][a-zA-Z]{2,3}$/i.test(this.userEmail)
      const {data} = await Vue.axios({
        url: '/user/emailCheck',
        method: 'POST',
        data: sendData
      });
      if(data){
        this.okEmail = data.isRegEmail;
        this.isRegEmail = data.isRegEmail
      }else{
        this.okEmail = false;
        this.isRegEmail = false;
      }
    }
  }
  async toastErrorMessage(message:string){
    Vue.$toast.open({
      message: message,
      type: 'error',
      duration: 5000
    });
  }

  async findPassword(){
    if(!this.okEmail || !this.userEmail.trim()) {
      await this.toastErrorMessage('이메일을 확인해주세요')
      return;
    }

    const sendData = {
      email: this.userEmail,
      type : AUTH_TYPE.FIND
    };

    this.pending = true;
    const { data } = await Vue.axios({
      url: '/mail/authEmailSend',
      method: 'POST',
      data: sendData
    });


    this.mailSend = data.result;
    this.pending = data.result;
  }


  mounted(){}

}
